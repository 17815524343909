export function expandContent() {
    const contents = document.querySelectorAll('[data-cut-content]');

    contents.forEach((content) => {
        content.addEventListener('click', () => {
            if (content.style.height === 'auto') {
                content.style.height = '';
                content.style.webkitLineClamp = '5';
            } else {
                content.style.height = 'auto';
                content.style.webkitLineClamp = 'unset';
            }
        });
    });
}
