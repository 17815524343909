import { initHamburger } from '../../../../Common/Resources/src_front/js/satellites/initHumburger';
import { expandContent } from '../../../../Common/Resources/src_front/js/satellites/expandContent';
import { initAccordion } from '../../../../Common/Resources/src_front/js/satellites/initAccordion';
import { scrollTo } from './scrollTo';

window.addEventListener('DOMContentLoaded', onLoad, false);

function onLoad() {
    initHamburger();
    expandContent();
    initAccordion();
    scrollTo();
}
