export const initHamburger = () => {
    const hamburger = document.querySelector('[data-hamburger]');
    if (!hamburger) return;
    const line = hamburger.querySelector('[data-hamburger-line]');
    const navMenu = document.querySelector('[data-menu]');
    const activeClass = 'active';

    hamburger.addEventListener('click', () => {
        line.classList.toggle(activeClass);
        navMenu.classList.toggle(activeClass);
    });
};
