export function initAccordion() {
    const accordions = document.querySelectorAll('[data-accordion]');

    accordions.forEach((accordion) => {
        const toggleButton = accordion.querySelector('[data-accordion-toggle]');
        const content = accordion.querySelector('[data-accordion-content]');

        toggleButton.addEventListener('click', () => {
            accordions.forEach((otherAccordion) => {
                if (otherAccordion !== accordion) {
                    otherAccordion.classList.remove('active');
                    otherAccordion.querySelector('[data-accordion-content]').style.maxHeight = null;
                }
            });

            accordion.classList.toggle('active');
            if (accordion.classList.contains('active')) {
                content.style.maxHeight = content.scrollHeight + 'px';
            } else {
                content.style.maxHeight = null;
            }
        });
    });
}
